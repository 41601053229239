@charset "utf-8";

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;1,100;1,200;1,300;1,400;1,500&display=swap');
@import url('bootstrap/dist/css/bootstrap.min.css');

html,
body {
	font-family: 'Poppins', sans-serif;
}

.nav-link img {
	width: 10%;
	margin-right: 10px;
}

.card-header img {
	width: 10%;
	margin-right: 10px;
}

.list-group-item img {
	width: 30%;
}
